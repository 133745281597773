import React, {Fragment, useEffect, useRef, useState} from 'react';
import { css } from '@emotion/css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import StrangestFictionPoster from '../new_images/branding/StrangestFictionFinalVersion.png';
import { Facebook, Twitter, Linkedin, Envelope, Check } from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";
import EmailShare from 'react-email-share-link';
import { getDBRef, getStorageRef } from '../FirebaseActions';
import { Spinner } from 'react-bootstrap';
import {connect, useDispatch} from 'react-redux';

import { PayPalDonationButton } from '../components/PayPalDonationButton';

import Campfire from '../new_images/bonfire.png';

import './SubmitPage.css';

const SubmitPage = (props) => {
    const [name, setName] = useState('');
    const [penName, setPenName] = useState('');
    const [email, setEmail] = useState('');
    const [bio, setBio] = useState('');
    const [storyTitle, setStoryTitle] = useState('');
    const [file, setFile] = useState(null);

    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [serverError, setServerError] = useState(false);
    const [socMedia, setSocMedia] = useState('');

    const dispatch = useDispatch();

    let title = '';
    let dueDate = '';
    let tweetText = '';

    const isAnthologyV2 = window.location.pathname.slice(8) === 'anthology-v2';
    const isCampfireStoriesV1 = window.location.pathname.slice(8) === 'campfire-v1';
    const link = window.location.pathname.slice(8);

    if (isAnthologyV2) {
        title = 'Strangest Fiction Anthology - Volume 2 Submissions';
        dueDate = 'Friday, January 1st, 2024';
        tweetText = 'Submit your best short story for consideration in the SF Anthology Volume 2';
    } else if (isCampfireStoriesV1) {
        title = 'Strangest Fiction Campfire Stories - Volume 1 Submissions';
        dueDate = 'Monday, March 3rd, 2025';
        tweetText = 'Submit your best spooky campfire story for consideration in our Summer 2025 collection.';
    } else {
        title = 'Strangest Fiction - Rolling Submissions';
        dueDate = '---';
        tweetText = 'Submit your strangest short fiction story for consideration in any future Strangest Fiction publications.';
    }

    const [validated, setValidated] = useState(false);

    const handleSubmit = async (event) => {
        const form = event.target;
        setLoading(true);

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setLoading(false);
            setErrorMessage('Error: Please check that all of the fields are valid and try again.');
        } else {
            setErrorMessage(null);
            setServerError(false);
            event.preventDefault();
            event.stopPropagation();

            const formData = new FormData(form);
            const formDataObj = Object.fromEntries(formData.entries());

            try {
                let db = getDBRef();

                //  Add the story to submissions
                let newStoryRef = db.collection("submissions").doc(link).collection("submissions").doc();

                // Add the file to storage based on submission ID
                var storageRef = getStorageRef();
                var newFileRef = storageRef.child('submission-files/' + link + '/' + newStoryRef.id + '/' + file.name);
                newFileRef.put(file).then((snapshot) => {
                    snapshot.ref.getDownloadURL().then((downloadURL) => {
                        // Set the story information in submissions list
                        newStoryRef.set({
                            name: name,
                            penName: penName,
                            title: storyTitle,
                            email: email,
                            socMedia: socMedia,
                            bio: bio,
                            id: newStoryRef.id,
                            timestamp: new Date(),
                            fileURL: downloadURL
                        });
                    });
                });

                // Add the submission to user info if applicable
                if (props.currentUser) {
                    let allSubmissions = {...props.currentUser.submissions};
                    if (!allSubmissions) {
                        allSubmissions = {
                            [link]: {
                                title: storyTitle,
                                status: 'review'
                            }
                        }
                    } else {
                        allSubmissions[link] = {
                            title: storyTitle,
                            status: 'review'
                        }
                    }

                    db.collection("users").doc(props.currentUser.uid).update({
                        submissions: allSubmissions
                    });
                }

                if (isCampfireStoriesV1) {
                    const response = await fetch('https://us-central1-strangest-fiction.cloudfunctions.net/sendMail', {
                        method: 'POST',
                        headers: {'Content-Type' : 'application/json'},
                        body: JSON.stringify({ email: email, storyTitle: storyTitle})
                    });

                    console.log(response);
                }
    
                setLoading(false);
                setSubmitted(true);
            } catch (e) {
                setLoading(false);
                setServerError(true);
            }
        }

        setValidated(true);
    };

    const fileUploaded = (event) => {
        let files = event.target.files;
        let storyFile = files[0];
        setFile(storyFile);
    }

    const showAccountModal = () => {
        dispatch({type: "SET_LOGIN_MODALS", payload: {
            showLoginModal: true,
            showCreateAccount: true,
            showSuccess: false,
            showPassReset: false
        }});
    }

    const closeModal = () => {
        props.history.push('/');
    }

    // useEffect(() => {
    //     async function fetchData() {
    //         const response = await fetch('https://us-central1-strangest-fiction.cloudfunctions.net/sendMail', {
    //             method: 'POST',
    //             headers: {'Content-Type' : 'application/json'},
    //             body: JSON.stringify({ email: 'dhwautlet@gmail.com', storyTitle: 'Sample Story Title'})
    //         });
    
    //         console.log(response);
    //     }
    //     fetchData();

    // }, [])

    return (
        <div className={css`background-color: white;`}>
            <Navbar expand="xl" bg="light" data-bs-theme="light">
                <div className={css`display: flex; flex-direction: row; justify-content: space-between; width: 100%;`}>
                    <Nav className="me-auto">
                        <Nav.Link href="/">{'< Back to Strangest Fiction'}</Nav.Link>
                    </Nav>
                    <Nav className="me-auto">
                        {props.currentUser && (
                            <Nav.Link> {props.currentUser.username}</Nav.Link>
                        )}
                        {!props.currentUser && (
                            <Nav.Link onClick={showAccountModal}>Login</Nav.Link>
                        )}
                    </Nav>
                </div>
            </Navbar>

            <div className={css`display: flex; flex-direction: row; justify-content: center; margin-top: 20px;`}>
                <img src={StrangestFictionPoster} alt="temp icon" className={css`
                    width: 150px;
                    height: 150px;
                `}/>

                {isCampfireStoriesV1 && (
                    <img src={Campfire} alt="temp icon" className={css`
                        width: 150px;
                        height: 150px;
                    `}/>
                )}
            </div>

            <div className='submit-form-container'>
                <div className={css`display: flex; flex-align: row; justify-content: flex-end;`}>
                    <a href={"https://www.facebook.com/sharer/sharer.php?u=https://strangestfiction.com/submit/" + link} target="_blank">
                        <div className={css`border-style: solid; border-width: 1px; padding: 10px; margin: 5px; border-color: lightgray; border-radius: 50%;`}><Facebook size={20}/></div>
                    </a>
                    <a href={`https://twitter.com/intent/tweet?text=${tweetText}! Click the link to submit a story: https://strangestfiction.com/submit/${link}`} data-size="large" target="_blank">
                        <div className={css`border-style: solid; border-width: 1px; padding: 10px; margin: 5px; border-color: lightgray; border-radius: 50%;`}><Twitter size={20}/></div>
                    </a>
                    <a href={`https://www.linkedin.com/sharing/share-offsite/?url=https://strangestfiction.com/submit/${link}`}>
                        <div className={css`border-style: solid; border-width: 1px; padding: 10px; margin: 5px; border-color: lightgray; border-radius: 50%;`}><Linkedin size={20}/></div>
                    </a>

                    <EmailShare email="mickey@mouse.com" subject={title} body={`Click here to submit a short story for consideration to be included in ${title}: https://strangestfiction.com/submit/${link}`}>
                        {link => (
                            <a href={link} data-rel="external"><div className={css`border-style: solid; border-width: 1px; padding: 10px; margin: 5px; border-color: lightgray; border-radius: 50%;`}><Envelope size={20}/></div></a>
                        )}
                    </EmailShare>
                </div>
                <h4 className={css`padding-left: 10px;`}>{title}</h4>
                <div className={css`padding-left: 10px;`}>{`Due by ${dueDate}`}</div>

                <div className={css`padding-left: 20px; padding-top: 20px;`}>
                    {isAnthologyV2 && (
                        <div>
                            Word Limit: 2,500 - 5,000 <br/><br/>
                            Strangest Fiction is accepting submissions for our second annual short story collection. We are looking for the best science fiction, supernatural, horror, historical fiction, and thriller works by up and coming authors. We will not descriminate based on previous publication experience.<br/><br/>
                            Please only submit one piece of work at a time. Duplicate submissions will not be considered. Please make your submission as free of grammatical and spelling errors as possible. Submissions should be 1.5 or double spaced in Times New Roman font.<br/><br/>
                            At this time, authors previously published in the Strangest Fiction Anthology - Volume 1 will not be considered for Volume 2 and will have to wait for Volume 3.<br/><br/>
                            Find out more about Strangest Fiction here: <a href="https://strangestfiction.com/about" target="_blank">strangestfiction.com</a><br/><br/>
                            Create an account to see your submission status or submit as a guest. All authors will be notified via email by February 2024 of final submission status. <br/><br/>
                        </div>
                    )}

                    {isCampfireStoriesV1 && (
                        <div>
                            Word Limit: 2,000 <br/><br/>
                            Strangest Fiction is accepting submissions for our first ever Spooky Campfire Stories collection! <br/><br/>
                            Write the story like it is being told around a campfire in a group setting. We want a story that is dramatic and evokes terror from the listener. Twists and turns are a real plus. Surprise us! <br/> <br/>
                            Please only submit one piece of work at a time. Duplicate submissions will not be considered. Please make your submission as free of grammatical and spelling errors as possible. Submissions should be 1.5 or double spaced in Times New Roman font.<br/><br/>
                            Submissions are open to any authors even if previously published in Anthology V1 or V2! We will not descriminate based on previous publication experience.<br/><br/>
                            Find out more about Strangest Fiction here: <a href="https://strangestfiction.com/about" target="_blank">strangestfiction.com</a><br/><br/>
                            Create an account to see your submission status or submit as a guest. All authors will be notified via email of final submission status. <br/><br/>
                        </div>
                    )}

                    {!isAnthologyV2 && !isCampfireStoriesV1 && (
                        <div>
                            Word Limit: 2,500 - 5,000 <br/><br/>
                            Strangest Fiction is accepting rolling submissions for any future publications. Submit a story below and we will consider it for inclusion in our next collection. We are looking for the best science fiction, supernatural, horror, historical fiction, and thriller works by up and coming authors. We will not descriminate based on previous publication experience.<br/><br/>
                            Please only submit one piece of work at a time. Duplicate submissions will not be considered. Please make your submission as free of grammatical and spelling errors as possible. Submissions should be 1.5 or double spaced in Times New Roman font.<br/><br/>
                            Find out more about Strangest Fiction here: <a href="https://strangestfiction.com/about" target="_blank">strangestfiction.com</a><br/><br/>
                            Create an account to see your submission status or submit as a guest. All authors will be notified via email of final submission status. <br/><br/>
                        </div>
                    )}

                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Legal Name</Form.Label>
                            <Form.Control type="text" placeholder="John Smith" value={name} onChange={(e) => setName(e.target.value)} required/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="penName">
                            <Form.Label>Pen Name (Optional)</Form.Label>
                            <Form.Control type="text" placeholder="Jane Doe"  value={penName} onChange={(e) => setPenName(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="name@example.com" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Social Media Handles</Form.Label>
                            <Form.Control type="text" placeholder="" value={socMedia} onChange={(e) => setSocMedia(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="bio">
                            <Form.Label>Short Bio of Yourself (200 words max)</Form.Label>
                            <Form.Control as="textarea" rows={3}  value={bio} onChange={(e) => setBio(e.target.value)} required/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="title">
                            <Form.Label>Story Title</Form.Label>
                            <Form.Control type="text" placeholder="Sample Title" value={storyTitle} onChange={(e) => setStoryTitle(e.target.value)} required/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="storySubmission" >
                            <Form.Label>Story Submission</Form.Label>
                            <Form.Control type="file" size="lg" onChange={fileUploaded} required/>
                        </Form.Group>

                        {errorMessage && <div className={css`paddingTop: 10px; paddingBottom: 10px; color: red; `}>{errorMessage}</div>}

                        {serverError && <div className={css`paddingTop: 10px; paddingBottom: 10px; color: red; `}>Server Error: We encountered an error with our system, please try again later.</div>}

                        {loading && <Spinner animation="grow" variant="success" size="sm"/>}

                        <div className={css`display: flex; flex-direction: row; justify-content: flex-end; padding-top: 15px;`}>
                            <Button type="submit" size='lg'>Submit Story</Button>
                        </div>
                    </Form>
                </div>
            </div>

            {submitted && (
                <Modal show={submitted} onHide={closeModal} size="lg">
                    <Modal.Header closeButton>
                        <div className="center-content">
                            <h3>
                                Submitted Successfully
                            </h3>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="center-content">
                            <div className="short-description">
                                Your story <b>{storyTitle}</b> has been submitted successfully! We will follow up with more info when available. Thank you for your submission. <br/> <br/>
                                Would you like to support our mission? Please click the PayPal link below if you'd like to donate. <br/><br/>
                                <div className={css`display: flex; flex-direction: row; justify-content: center; width: 100%;`}><PayPalDonationButton/></div>
                            </div>
                        </div>
                        <div className="center-content">
                            <Button variant="primary" size="lg" className="pad-right-10" onClick={closeModal}>
                                Close
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            )}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.primary.currentUser,
    }
}

export default connect (mapStateToProps) (SubmitPage);