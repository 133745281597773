import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import PrivateRoute from '../_PrivateRoute';
import HomeDarkPage from "../HomeDarkPage";
// import PublicRoute from '../_PublicRoute';

import CategoryPage from '../CategoryPage';

import CategoriesPage from '../CategoriesPage';

import AllStoriesPage from '../AllStoriesPage';

import ScrollToTop from '../ScrollToTop';

import StoryPage from '../StoryPage';
import WriteStoryPage from '../WriteStoryPage';

import ReviewsPage from '../ReviewsPage';
import ReviewTheKeep from '../ReviewPages/ReviewTheKeep';
import ReviewTheTomb from '../ReviewPages/ReviewTheTomb';
import ReviewTheInvisibleMan from '../ReviewPages/ReviewTheInvisibleMan';
import ReviewTheTimeMachine from '../ReviewPages/ReviewTheTimeMachine';
import ReviewUnsolicited from '../ReviewPages/ReviewUnsolicited';

import AccountPage from '../AccountPage';
import UserPage from '../UserPage';
import WritersPage from '../WritersPage';

import ContactUs from '../ContactUs';
import AboutUsPage from '../AboutUsPage';
import PrivacyPolicyPage from '../PrivacyPolicyPage';
import CommunityGuidelines from '../CommunityGuidelines';

import NewsletterPage from '../NewsletterPage';
import PromptsListPage from '../PromptsListPage';
import PromptPage from '../PromptPage';
import SingleCompetition from '../competitions/SingleCompetition';
import CompetitionsPage from '../competitions/CompetitionsPage';
import CompetitionTerms from '../competitions/CompetitionTerms';
import AnnouncementPage from '../AnnouncementPage';
import PublicationsPage from '../Publications/PublicationsPage';
import SinglePublicationPage from '../Publications/SinglePublicationPage';
import MerchPage from '../Merch/MerchPage';
import SubmitPage from '../SubmitPage';
import {connect} from 'react-redux';
import ReviewBarbarian from '../ReviewPages/ReviewBarbarian';
import ReviewTheEyes from '../ReviewPages/ReviewTheEyes';

class Routes extends React.Component {


    constructor(props) {
        super(props);
        this.state = {        
        };
    }

    render() {
        return (
            <>
                <ScrollToTop/>
                <Switch>
                    {/*home one routes*/}
                    <PrivateRoute
                        exact
                        path="/"
                        component={HomeDarkPage}/>
    
                    {/*home dark version*/}
                    <PrivateRoute
                        exact
                        path="/dark"
                        component={HomeDarkPage}/>
                    <PrivateRoute
                        exact
                        path="/categories"
                        component={CategoriesPage}/>
                    <PrivateRoute
                        exact
                        path="/category/:category"
                        component={CategoryPage}/>
                    <PrivateRoute
                        exact
                        path="/allstories"
                        component={AllStoriesPage}/>
                    <PrivateRoute
                        exact
                        path="/writers"
                        component={WritersPage}/>
                    {this.props.currentUser != null && (
                        <PrivateRoute
                            exact
                            path="/account"
                            component={AccountPage}/>
                    )}
                    <PrivateRoute
                        exact
                        path="/user/:userId"
                        component={UserPage}/>
                    {this.props.currentUser != null && (
                        <PrivateRoute
                            exact
                            path="/write"
                            component={WriteStoryPage}/>
                    )}
                    <PrivateRoute
                        exact
                        path="/contact"
                        component={ContactUs}/>
                    <PrivateRoute
                        exact
                        path="/about"
                        component={AboutUsPage}/>
                    <PrivateRoute
                        exact
                        path="/story/:storyId"
                        component={StoryPage}/>
                    <PrivateRoute
                        exact
                        path="/privacy"
                        component={PrivacyPolicyPage}/>
                    <PrivateRoute
                        exact
                        path="/guidelines"
                        component={CommunityGuidelines}/>
                    <PrivateRoute
                        exact
                        path="/competition-terms"
                        component={CompetitionTerms}/>
                    <PrivateRoute
                        exact
                        path="/announcements"
                        component={NewsletterPage}/>
                    <PrivateRoute
                        exact
                        path="/prompt/:promptId"
                        component={PromptPage}/>
                    <PrivateRoute
                        exact
                        path="/prompts"
                        component={PromptsListPage}/>
                    <PrivateRoute
                        exact
                        path="/announcements/:title"
                        component={AnnouncementPage}/>
                    <PrivateRoute
                        exact
                        path="/publications"
                        component={PublicationsPage}/>
                    <PrivateRoute
                        exact
                        path="/publications/:title"
                        component={SinglePublicationPage}/>
                    <PrivateRoute
                        exact
                        path="/merch"
                        component={MerchPage}/>
                    <PrivateRoute
                        exact
                        path="/competitions"
                        component={CompetitionsPage}/>
                    <PrivateRoute
                        exact
                        path="/competitions/:id"
                        component={SingleCompetition}/>
                    <PrivateRoute
                        exact
                        path="/submit/:id"
                        component={SubmitPage}/>
                    <PrivateRoute
                        exact
                        path="/reviews"
                        component={ReviewsPage}/>
                    <PrivateRoute
                        exact
                        home_style={4}
                        path="/reviews/barbarian"
                        parentClass="dark-theme primay_bg"
                        component={ReviewBarbarian}/>
                    <PrivateRoute
                        exact
                        home_style={4}
                        path="/reviews/theeyes"
                        parentClass="dark-theme primay_bg"
                        component={ReviewTheEyes}/>
                    <PrivateRoute
                        exact
                        home_style={4}
                        path="/reviews/the-keep"
                        parentClass="dark-theme primay_bg"
                        currentUser={this.props.currentUser}
                        allUsers={this.props.allUsers}
                        allStories={this.props.allStories}
                        reloadAllStories={this.props.reloadAllStories}
                        component={ReviewTheKeep}/>
                    <Route exact>
                        <Redirect to="/" />
                    </Route>
                    

                    {/*
                    <PrivateRoute
                        exact
                        home_style={4}
                        path="/reviews/the-tomb"
                        parentClass="dark-theme primay_bg"
                        currentUser={this.props.currentUser}
                        allUsers={this.props.allUsers}
                        allStories={this.props.allStories}
                        reloadAllStories={this.props.reloadAllStories}
                        component={ReviewTheTomb}/>
                    <PrivateRoute
                        exact
                        home_style={4}
                        path="/reviews/the-time-machine"
                        parentClass="dark-theme primay_bg"
                        currentUser={this.props.currentUser}
                        allUsers={this.props.allUsers}
                        allStories={this.props.allStories}
                        reloadAllStories={this.props.reloadAllStories}
                        component={ReviewTheTimeMachine}/>
                    <PrivateRoute
                        exact
                        home_style={4}
                        path="/reviews/the-invisible-man"
                        parentClass="dark-theme primay_bg"
                        currentUser={this.props.currentUser}
                        allUsers={this.props.allUsers}
                        allStories={this.props.allStories}
                        component={ReviewTheInvisibleMan}/>
                    <PrivateRoute
                        exact
                        home_style={4}
                        path="/reviews/unsolicited"
                        parentClass="dark-theme primay_bg"
                        currentUser={this.props.currentUser}
                        allUsers={this.props.allUsers}
                        allStories={this.props.allStories}
                        reloadAllStories={this.props.reloadAllStories}
                        component={ReviewUnsolicited}/> */}
                </Switch>
            </>
        );
    }

    
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.primary.currentUser
    }
}

export default connect (mapStateToProps) (Routes)