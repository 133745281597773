import React, {Fragment} from 'react';
import BreadCrumb from "../components/BreadCrumb";
import {Link} from "react-router-dom";

import './CategoryPages.css';

import '../components/primary-styles.css';

import './AccountPage.css';

import tagIcon from '../new_images/pricetag-icon.png';
import eyeIcon from '../new_images/icons/blue-eye-icon.png';
import thumbIcon from '../new_images/icons/white-thumb-icon.png';
import commentsIcon from '../new_images/icons/white-comment-icon.png';
import penIcon from '../new_images/icons/pen-icon.png';
import plusIcon from '../new_images/icons/white-plus-icon.png';
import shareSmallIcon from '../new_images/icons/white-share-icon.png';
import notificationIcon from '../new_images/white-notification-icon.png';
import messagesIcon from '../new_images/icons/white-chat-icon.png';
import previousIcon from '../new_images/icons/previous-icon.png';
import submitIcon from '../new_images/icons/submit.png';

import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import StoryPreview from './StoryPreview';
import Messages from './Messages/Messages';

import LoggedOutModal from './LoggedOutModal';
import DeleteStoryModal from './DeleteStoryModal';
import OpenImageModal from './OpenImageModal';
import EditStoryModal from './EditStoryModal';
import AddMoreInfoModal from './AddMoreInfoModal';

import darkUserIcon from '../new_images/icons/dark-user-icon.png';
import thumbsUpImage from '../new_images/thumbs_up.png';

import supernaturalImage from '../new_images/category-images/supernatural-default-image.jpg';
import scifiImage from '../new_images/category-images/scifi-default-image.jpg';
import thrillerImage from '../new_images/category-images/thriller-default-image.jpg';
import horrorImage from '../new_images/category-images/horror-default-image.jpg';
import histFicImage from '../new_images/category-images/histfic-default-image.jpg';
import fantasyImage from '../new_images/category-images/fantasy-default-image.jpg';

import settingsIcon from '../new_images/icons/settings-icon.png';
import whiteAccountIcon from '../new_images/icons/white-account-icon.png';
import savedForLaterIcon from '../new_images/icons/saved-for-later-icon.png';
import whiteBookIcon from '../new_images/icons/white-book-icon.png';
import whiteFavoriteIcon from '../new_images/icons/white-favorite-icon.png';

import { toFullMonth } from '../utils/commonFunctions';
import { connect } from 'react-redux';
import { getAuthRef, getDBRef, getInitialDataFB, logPageView, regrabUserInfoFB, requestSubmissionDetails } from '../FirebaseActions';

class AccountPage extends React.Component {

    constructor(props) {
        super(props);

        this.storiesRef = React.createRef();
        this.accountRef = React.createRef();
        this.settingsRef = React.createRef();
        this.addPromptRef = React.createRef();
        this.submissionsRef = React.createRef();
        this.adminSubmissionsRef = React.createRef();

        this.socialMediaError = React.createRef();
        this.emailSavedError = React.createRef();

        this.publishedButtonRef = React.createRef();
        this.savedForLaterButtonRef = React.createRef();

        this.db = getDBRef();

        this.state = {        
            stories: true,
            account: false,
            settings: false,
            favorites: false,
            submissions: false,
            adminSubmissions: false,
            addPrompt: false,
            fullnameClicked: false,
            descriptionClicked: false,
            emailClicked: false,
            fullName: "",
            description: "",
            email: "",
            showLoggedOutModal: false,
            userStories: [],
            userSavedStories: [],
            showDeleteModal: false,
            storyToDelete: null,
            openImageModal: false,
            twitterLink: "",
            instagramLink: "",
            otherLink: "",
            showEditModal: false,
            storyToEdit: null,
            showAddMoreModal: false,
            promptToAdd: "",
            promptAdded: false,
            notifications: false,
            showPublished: true,
            showSavedForLater: false,
            messages: false,
            hideTabSelection: false,
            isMobile: window.screen.width < 767,
            selectedSubmissionPrompt: '',
        };

        this.fullnameClicked = this.fullnameClicked.bind(this);
        this.logoutClicked = this.logoutClicked.bind(this);
        this.matchesID = this.matchesID.bind(this);
        this.closeEditModalStoryEdited = this.closeEditModalStoryEdited.bind(this);
        this.grabContactUs = this.grabContactUs.bind(this);
        this.grabContactUsHelper = this.grabContactUsHelper.bind(this);
    }

    setFalseRemoveAll() {
        this.setState(() => { return {stories: false, account: false, settings: false, favorites: false, addPrompt: false, contactUs: false, publications: false, notifications: false, messages: false, submissions: false, adminSubmissions: false}});
        document.getElementById("settings-tab").classList.remove("tab-clicked");
        document.getElementById("account-tab").classList.remove("tab-clicked");
        document.getElementById("favorites-tab").classList.remove("tab-clicked");
        document.getElementById("stories-tab").classList.remove("tab-clicked");
        document.getElementById("messages-tab").classList.remove("tab-clicked");
        document.getElementById("submissions-tab").classList.remove("tab-clicked");
        if (this.props.currentUser.isAdmin) {
            document.getElementById("add-prompt-tab").classList.remove("tab-clicked");
            document.getElementById("contact-us-tab").classList.remove("tab-clicked");
            document.getElementById("publications-tab").classList.remove("tab-clicked");
            document.getElementById("adminsubmissions-tab").classList.remove("tab-clicked");
        }
        document.getElementById("notifications-tab").classList.remove("tab-clicked");
    }

    setFalseAll() {
        this.setState(() => { return {stories: false, account: false, settings: false, favorites: false, addPrompt: false, notifications: false, messages: false, submissions: false, adminSubmissions: false}});
    }

    storiesClicked() {
        if (!this.state.isMobile) this.setFalseRemoveAll();
        else this.setFalseAll();
        this.setState(() => { return {stories: true}});

        if (this.state.isMobile) this.setState({hideTabSelection: true});
        if (!this.state.isMobile) document.getElementById("stories-tab").classList.add("tab-clicked");
    }

    accountClicked() {
        if (!this.state.isMobile) this.setFalseRemoveAll();
        else this.setFalseAll();
        this.setState(() => { return {account: true}});

        if (this.state.isMobile) this.setState({hideTabSelection: true});
        if (!this.state.isMobile) document.getElementById("account-tab").classList.add("tab-clicked");
    }

    settingsClicked() {
        if (!this.state.isMobile) this.setFalseRemoveAll();
        else this.setFalseAll();
        this.setState(() => { return {settings: true }});

        if (this.state.isMobile) this.setState({hideTabSelection: true});
        if (!this.state.isMobile) document.getElementById("settings-tab").classList.add("tab-clicked");
    }

    favoritesClicked() {
        if (!this.state.isMobile) this.setFalseRemoveAll();
        else this.setFalseAll();
        this.setState(() => { return {favorites: true}});

        if (this.state.isMobile) this.setState({hideTabSelection: true});
        if (!this.state.isMobile) document.getElementById("favorites-tab").classList.add("tab-clicked");
    }

    submissionsClicked() {
        if (!this.state.isMobile) this.setFalseRemoveAll();
        else this.setFalseAll();
        this.setState(() => { return {submissions: true}});

        if (this.state.isMobile) this.setState({hideTabSelection: true});
        if (!this.state.isMobile) document.getElementById("submissions-tab").classList.add("tab-clicked");
    }

    adminSubmissionsClicked() {
        if (!this.state.isMobile) this.setFalseRemoveAll();
        else this.setFalseAll();
        this.setState(() => { return {adminSubmissions: true}});

        if (this.state.isMobile) this.setState({hideTabSelection: true});
        if (!this.state.isMobile) document.getElementById("adminsubmissions-tab").classList.add("tab-clicked");
    }

    contactUsClicked() {
        if (!this.state.isMobile) this.setFalseRemoveAll();
        else this.setFalseAll();
        this.setState(() => {return {contactUs: true}});

        if (this.state.isMobile) this.setState({hideTabSelection: true});
        if (!this.state.isMobile) document.getElementById("contact-us-tab").classList.add("tab-clicked");
    }

    publicationClicked() {
        if (!this.state.isMobile) this.setFalseRemoveAll();
        else this.setFalseAll();
        this.setState(() => {return {publications: true}});

        if (this.state.isMobile) this.setState({hideTabSelection: true});
        if (!this.state.isMobile) document.getElementById("publications-tab").classList.add("tab-clicked");
    }

    addPromptClicked() {
        if (!this.state.isMobile) this.setFalseRemoveAll();
        else this.setFalseAll();
        this.setState(() => {return {addPrompt: true}});

        if (this.state.isMobile) this.setState({hideTabSelection: true});
        if (!this.state.isMobile) document.getElementById("add-prompt-tab").classList.add("tab-clicked");
    }

    notificationsClicked() {
        if (!this.state.isMobile) this.setFalseRemoveAll();
        else this.setFalseAll();
        this.setState({notifications: true});

        if (this.state.isMobile) this.setState({hideTabSelection: true});
        if (!this.state.isMobile) document.getElementById("notifications-tab").classList.add("tab-clicked");
    }

    messagesClicked() {
        if (!this.state.isMobile) this.setFalseRemoveAll();
        else this.setFalseAll();
        this.setState({messages: true});

        if (this.state.isMobile) this.setState({hideTabSelection: true});
        if (!this.state.isMobile) document.getElementById("messages-tab").classList.add("tab-clicked");
    }

    addPrompt() {
        let newPrompt = this.db.collection("prompts").doc();
        newPrompt.set({
            id: newPrompt.id,
            storyIds: [],
            timestamp: Date.now(),
            title: this.state.promptToAdd
        });

        this.setState({promptToAdd: "", promptAdded: true})
    }

    fullnameClicked() {
        this.setState({fullnameClicked: true}, () => {
            document.getElementById("fullname-input").focus();
        })
    }

    fullnameSaved() {
        this.db.collection("users").doc(this.props.currentUser.uid).update({
            fullname: this.state.fullName
        })
        .then(() => {
            regrabUserInfoFB(this.props.dispatch, this.props.currentUser.uid);
            this.setState({fullnameClicked: false});
        });
    }

    descriptionClicked() {
        this.setState({descriptionClicked: true}, () => {
            document.getElementById("description-input").focus();
        })
    }

    descriptionSaved() {
        this.db.collection("users").doc(this.props.currentUser.uid).update({
            description: this.state.description
        })
        .then(() => {
            regrabUserInfoFB(this.props.dispatch, this.props.currentUser.uid);
            this.setState({descriptionClicked: false});
        });
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    emailClicked() {
        this.setState({emailClicked: true}, () => {
            document.getElementById("email-input").focus();
        })
    }

    emailSaved() {
        this.emailSavedError.current.style.display="none";
        //console.log(this.state.email)
        if (this.validateEmail(this.state.email)) {
            getAuthRef().currentUser.updateEmail(this.state.email).then(() => {
                this.db.collection("users").doc(this.props.currentUser.uid).update({
                    email: this.state.email
                }).then(() => {
                    regrabUserInfoFB(this.props.dispatch, this.props.currentUser.uid);
                    this.setState({emailClicked: false});
                    getAuthRef().currentUser.sendEmailVerification().then(function() {
                    // Email sent.
                    }).catch(function(error) {
                    // An error happened.
                    });
                })
            }).catch((error) => {
                this.emailSavedError.current.style.display="block";
            })
        } else {
            this.emailSavedError.current.style.display="block";
        }
    }

    logoutClicked() {
        this.setState({showLoggedOutModal: true});
    }

    closeLoggedOutModal() {
        this.setState({showLoggedOutModal: false});
        this.props.history.push('/');
        getAuthRef().signOut();
    }

    deleteClicked(e, story) {
        e.stopPropagation();
        this.setState({showDeleteModal: true, storyToDelete: story});
    }

    editClicked(e, story) {
        e.stopPropagation();
        if (story.allStoryInformation.competitionId == null) {
            this.setState({showEditModal: true, storyToEdit: story});
        } else {
            alert("Stories that are part of an active competition can't be edited.");
        }
    }

    closeEditModal() {
        this.setState({showEditModal: false, storyToEdit: null});
        document.getElementById('up_btn').style.display = 'block';
    }

    closeEditModalStoryEdited() {
        this.setState({showEditModal: false, storyToEdit: null, userSavedStories: []});
        getInitialDataFB(this.props.dispatch);
        
        if (document.getElementById('up_btn') != null) {
            document.getElementById('up_btn').style.display = 'block';
        }
    }

    closeDeleteModal() {
        this.setState({showDeleteModal: false, storyToDelete: null});
    }

    closeModalStoryDeleted() {
        this.setState({showDeleteModal: false, storyToDelete: null, userStories: []});
        getInitialDataFB(this.props.dispatch);
    }

    matchesID(story) {
        return story.creatorID == this.props.currentUser.uid;
    }

    async getUserStories() {

        let userStories = this.props.allStories.filter(this.matchesID);

        this.setState({userStories: userStories})

        await this.db.collection("users").doc(this.props.currentUser.uid).collection("saved-stories").get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                this.state.userSavedStories.push(doc.data());
            });
        });

        this.setState(() => {return {userStories: userStories, userSavedStories: this.state.userSavedStories}});
    }

    storyClicked(storyId) {
        this.props.history.push('/story/' + storyId);
    }

    savedStoryClicked(story) {
        //console.log(story);
        this.props.history.push('/write');
        // this.props.sendSavedInfo(story);
        this.props.dispatch({type: "SET_SAVED_STORY", payload: story});
    }

    openImageModal() {
        this.setState({openImageModal: true});
    }

    closeImageModal() {
        this.setState({openImageModal: false});
    }

    makeProfilePublic() {
        this.db.collection("users").doc(this.props.currentUser.uid).update({
            isProfilePublic: true
        })
        .then(() => {
            regrabUserInfoFB(this.props.dispatch, this.props.currentUser.uid);
        });
    }

    makeProfilePrivate() {
        this.db.collection("users").doc(this.props.currentUser.uid).update({
            isProfilePublic: false
        })
        .then(() => {
            regrabUserInfoFB(this.props.dispatch, this.props.currentUser.uid);
        });
    }

    validURL(string) {
        // let url;
        
        // try {
        //   url = new URL(string);
        // } catch (_) {
        //   return false;  
        // }
      
        // return url.protocol === "http:" || url.protocol === "https:";

        return true;
    }

    socialMediaSaved() {
        this.socialMediaError.current.style.display="none";
        if ((this.state.twitterLink === "" || this.validURL(this.state.twitterLink)) && 
            (this.state.instagramLink === "" || this.validURL(this.state.instagramLink)) && 
            (this.state.otherLink === "" || this.validURL(this.state.otherLink))) {
                let twitterLink = this.state.twitterLink;
                let instagramLink = this.state.instagramLink;
                let otherLink = this.state.otherLink;
                if (twitterLink == null) twitterLink = "";
                if (instagramLink == null) instagramLink = "";
                if (otherLink == null) otherLink = "";
                this.db.collection("users").doc(this.props.currentUser.uid).update({
                    twitterLink: twitterLink,
                    instagramLink: instagramLink,
                    otherLink: otherLink
                })
                .then(() => {
                    regrabUserInfoFB(this.props.dispatch, this.props.currentUser.uid);
                    document.getElementById('social-media-saved').style.display = 'block';
                });
        } else {
            this.socialMediaError.current.style.display="block";
        }
    }

    checkFields() {
        if (this.props.currentUser.description == "") {
            this.setState({showAddMoreModal: true});
        }
    }

    closeAddMoreModal() {
        this.setState({showAddMoreModal: false});
    }

    async getEmailList() {
        const snapshot = await this.db.collection('email-list').get();
        return snapshot.docs.map(doc => doc.data());
    }

    downloadEmailList() {
        this.getEmailList().then(allEmails => {
            //console.log(allEmails)

            let emailsArr = []
            for (var i = 0; i < allEmails.length; i++) {
                emailsArr.push(allEmails[i].email)
            }

            let csvContent = "data:text/csv;charset=utf-8," 
            + emailsArr.join("\n");

            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "my_data.csv");
            document.body.appendChild(link); // Required for FF
            
            link.click(); // This will download the data file named "my_data.csv".
        });

        const rows = [
            ["name1", "city1", "some other info"],
            ["name2", "city2", "more info"]
        ];
        
    }

    notificationClicked(notification, index) {

        // Update individual notification in array
        let allNotifications = this.props.currentUser.notifications;
        allNotifications.list[index].read = true;

        // Check to see if app wide notification should be removed
        let shouldRemoveDot = true;
        allNotifications.list.map((note) => {
            if (note.read == false) {
                shouldRemoveDot = false;
            }
        });
        if (shouldRemoveDot) {
            allNotifications.hasActive = false;
        }

        // Update notifications in DB
        this.db.collection("users").doc(this.props.currentUser.uid).update({
            'notifications': allNotifications
        })
        .then(() => {
            // Should update entire app user data (if needed)
        });
    }

    clearAllNotifications() {
        // Clear all notifications
        let allNotifications = this.props.currentUser.notifications;
        allNotifications.list.map((note) => {
            note.read = true;
        });
        allNotifications.hasActive = false;

        this.db.collection("users").doc(this.props.currentUser.uid).update({
            'notifications': allNotifications
        })
        .then(() => {
            // Should update entire app user data (if needed)
            regrabUserInfoFB(this.props.dispatch, this.props.currentUser.uid);
        });
    }

    setButtonUnderline(buttonRef, refToDelete) {
        if (!buttonRef.current.classList.contains("blue-underline")) {
            buttonRef.current.classList.add("blue-underline");
        }
        refToDelete.current.classList.remove("blue-underline");
    }

    async grabContactUsHelper() {
        const snapshot = await this.db.collection('contact-us').get();
        return snapshot.docs.map(doc => doc.data());
    }

    grabContactUs() {
        this.grabContactUsHelper().then(contactUsMessages => {
            this.setState(() => {return {contactUsMessages: contactUsMessages}})
        }).catch((e) => console.log(e));
    }

    clickedReadContactUs(title) {
        this.db.collection("contact-us").doc(title).update({
            'read': true
        })
    }

    componentDidMount() {
        this.getUserStories();
        this.setState(() => {return {fullName: this.props.currentUser.fullname,
            email: this.props.currentUser.email,
            description: this.props.currentUser.description,
            twitterLink: this.props.currentUser.twitterLink,
            instagramLink: this.props.currentUser.instagramLink,
            otherLink: this.props.currentUser.otherLink}});
        //console.log(this.props.currentUser)
        this.checkFields();
        logPageView(window.location.pathname);

        // Check for URL params
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.has("messages")) {
            this.messagesClicked();
        }

        // Grab contact us if admin
        if (this.props.currentUser.isAdmin) this.grabContactUs();
    }

    render() {
        return (
            <Fragment>
                <BreadCrumb title="Business"/>
                <div className="archives padding-top-50">
                    <div className="container">
                        <h3>Welcome, {this.props.currentUser.username}</h3>
                        <div className="straight-line"></div>
                        <div className="row">
                            <div className="col-lg-4">
                                {!this.state.hideTabSelection && (
                                    <div className="account-tabs">
                                        <div className="account-tab tab-clicked" id="stories-tab" onClick={() => { this.storiesClicked()}}>
                                            <img src={whiteBookIcon} alt="Short Stories icon" className="account-tab-icon" />
                                            <div className="account-tab-title">
                                                Your Stories
                                            </div>
                                        </div>
                                        <div className="account-tab-border" />
                                        <div className="account-tab" id="submissions-tab" ref={this.submissionsRef} onClick={() => { this.submissionsClicked()}}>
                                            <img src={submitIcon} alt="Submissions Icon" className="account-tab-icon" />
                                            <div className="account-tab-title">
                                                Submissions
                                            </div>
                                        </div>
                                        <div className="account-tab-border" />
                                        <div className="account-tab" id="notifications-tab" onClick={() => { this.notificationsClicked()}}>
                                            <img src={notificationIcon} alt="notifications icon" className="account-tab-icon" />
                                            <div className="account-tab-title">
                                                Notifications
                                            </div>
                                            {this.props.currentUser.notifications?.hasActive && (
                                                <div className="notification-dot-container"><div className="notification-dot" /></div>
                                            )}
                                        </div>
                                        <div className="account-tab-border" />
                                        <div className="account-tab" id="messages-tab" onClick={() => { this.messagesClicked()}}>
                                            <img src={messagesIcon} alt="Messages icon" className="account-tab-icon" />
                                            <div className="account-tab-title">
                                                Messages
                                            </div>
                                            {this.props.currentUser.hasActiveMessage && (
                                                <div className="notification-dot-container"><div className="notification-dot" /></div>
                                            )}
                                        </div>
                                        <div className="account-tab-border" />
                                        <div className="account-tab" id="favorites-tab" ref={this.favoritesRef} onClick={() => { this.favoritesClicked()}}>
                                            <img src={whiteFavoriteIcon} alt="Saved for later icon" className="account-tab-icon" />
                                            <div className="account-tab-title">
                                                Favorites
                                            </div>
                                        </div>
                                        <div className="account-tab-border" />
                                        <div className="account-tab" id="account-tab" ref={this.accountRef} onClick={() => { this.accountClicked()}}>
                                            <img src={whiteAccountIcon} alt="Account info icon" className="account-tab-icon" />
                                            <div className="account-tab-title">
                                                Account Info
                                            </div>
                                        </div>
                                        <div className="account-tab-border" />
                                        <div className="account-tab" id="settings-tab" ref={this.settingsRef} onClick={() => { this.settingsClicked()}}>
                                            <img src={settingsIcon} alt="Settings icon" className="account-tab-icon" />
                                            <div className="account-tab-title">
                                                Settings
                                            </div>
                                        </div>
                                        {this.props.currentUser.isProfilePublic && (
                                            <>
                                                <div className="account-tab-border" />
                                                <Link className="account-tab" id="settings-tab" ref={this.settingsRef} to={"/user/" + this.props.currentUser.uid}>
                                                    <div className="account-tab-title">
                                                        View Profile Page
                                                    </div>
                                                </Link>
                                            </>
                                        )}
                                        {this.props.currentUser.isAdmin != null && (
                                            <>
                                                <div className="account-tab-border" />
                                                <div className="account-tab" id="adminsubmissions-tab" ref={this.adminSubmissionsRef} onClick={() => { this.adminSubmissionsClicked()}}>
                                                    <div className="account-tab-title">
                                                        Admin Submissions
                                                    </div>
                                                </div>
                                                <div className="account-tab-border" />
                                                <div className="account-tab" id="publications-tab" onClick={() => { this.publicationClicked()}}>
                                                    <div className="account-tab-title">
                                                        Stories for Publication
                                                    </div>
                                                </div>
                                                <div className="account-tab-border" />
                                                <div className="account-tab" id="contact-us-tab" onClick={() => { this.contactUsClicked()}}>
                                                    <div className="account-tab-title">
                                                        Contact Us Tickets
                                                    </div>
                                                </div>
                                                <div className="account-tab-border" />
                                                <div className="account-tab" id="add-prompt-tab" ref={this.addPromptRef} onClick={() => { this.addPromptClicked()}}>
                                                    <div className="account-tab-title">
                                                        Add Prompt (Admin)
                                                    </div>
                                                </div>
                                                <div className="account-tab-border" />
                                                <Link className="account-tab" id="settings-tab" onClick={() => { this.downloadEmailList()}}>
                                                    <div className="account-tab-title">
                                                        Download Emails (Admin)
                                                    </div>
                                                </Link>
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="col-lg-8">
                                {(this.state.hideTabSelection || !this.state.isMobile) && (
                                    <>
                                        {this.state.settings && (
                                            <>
                                                {this.state.hideTabSelection && this.state.isMobile && (
                                                    <div className="sub-account-nav-title">
                                                        <img src={previousIcon} alt="profile-image" className="prev-icon" onClick={() => this.setState({hideTabSelection: false})}/>
                                                        <h3>Settings</h3>
                                                        <div></div>
                                                    </div>
                                                )}
                                                <div className="account-info-container">
                                                   
                                                    {/* <div className="settings-field-container">
                                                        <div className="settings-field">Publish stories anonymously by default?</div>
                                                        <div class="custom-control custom-radio custom-control-inline">
                                                            <input type="radio" id="anon-default" name="anon-default" class="custom-control-input"/>
                                                            <label class="custom-control-label" for="anon-default">Yes</label>
                                                        </div>
                                                        <div class="custom-control custom-radio custom-control-inline">
                                                            <input type="radio" id="anon-default-no" name="anon-default" class="custom-control-input"/>
                                                            <label class="custom-control-label" for="anon-default-no">No</label>
                                                        </div>
                                                    </div> */}

                                                    <div className="settings-field-container">
                                                        <div className="settings-field">Make name and description publicly viewable? (Profile Page)</div>
                                                        <div class="custom-control custom-radio custom-control-inline">
                                                            {this.props.currentUser.isProfilePublic && (
                                                                <input type="radio" id="name-description" name="name-description" class="custom-control-input" onClick={() => { this.makeProfilePublic()}} checked/>
                                                            )}
                                                            {!this.props.currentUser.isProfilePublic && (
                                                                <input type="radio" id="name-description" name="name-description" class="custom-control-input" onClick={() => { this.makeProfilePublic()}}/>
                                                            )}
                                                            <label class="custom-control-label" for="name-description">Yes</label>
                                                        </div>
                                                        <div class="custom-control custom-radio custom-control-inline">
                                                            {this.props.currentUser.isProfilePublic && (
                                                                <input type="radio" id="name-description-no" name="name-description" class="custom-control-input" onClick={() => { this.makeProfilePrivate()}}/>
                                                            )}
                                                            {!this.props.currentUser.isProfilePublic && (
                                                                <input type="radio" id="name-description-no" name="name-description" class="custom-control-input" onClick={() => { this.makeProfilePrivate()}} checked/>
                                                            )}
                                                            <label class="custom-control-label" for="name-description-no">No</label>
                                                        </div>
                                                    </div>

                                                    {/* <div className="settings-field-container">
                                                        <div className="settings-field">Make profile image public?</div>
                                                        <div class="custom-control custom-radio custom-control-inline">
                                                            <input type="radio" id="image-public" name="image-public" class="custom-control-input"/>
                                                            <label class="custom-control-label" for="image-public">Yes</label>
                                                        </div>
                                                        <div class="custom-control custom-radio custom-control-inline">
                                                            <input type="radio" id="image-public-no" name="image-public" class="custom-control-input"/>
                                                            <label class="custom-control-label" for="image-public-no">No</label>
                                                        </div>
                                                    </div> */}

                                                    <div className="center-content">
                                                        <Button variant="primary" size="lg" onClick={() => {this.logoutClicked()}} className="logout-button">Logout</Button>
                                                    </div>
                                                </div>
                                              </>
                                            )}
                                            {this.state.account && (
                                                <>
                                                    {this.state.hideTabSelection && this.state.isMobile && (
                                                        <div className="sub-account-nav-title">
                                                            <img src={previousIcon} alt="profile-image" className="prev-icon" onClick={() => this.setState({hideTabSelection: false})}/>
                                                            <h3>Account Info</h3>
                                                            <div></div>
                                                        </div>
                                                    )}
                                                    <div className="account-info-container">
                                                        <div className="center-content">
                                                            <div className="profile-image-container">
                                                                <b className="profile-image-text">Photo</b>
                                                                {this.props.currentUser.imgUrl != null && (
                                                                    <img src={this.props.currentUser.imgUrl} alt="profile-image" className="profile-image-large"/>
                                                                )}
                                                                {this.props.currentUser.imgUrl == null && (
                                                                    <img src={darkUserIcon} alt="profile-image" className="profile-image-large"/>
                                                                )}
                                                                <div className="edit-image-text" onClick={() => {this.openImageModal()}}>Edit</div>
                                                            </div>
                                                        </div>

                                                        <div className="account-field-container">
                                                            <b>Username</b>
                                                            <div className="account-field-value">{this.props.currentUser.username}</div>
                                                            <div/>
                                                        </div>

                                                        {!this.state.emailClicked && (
                                                            <div className="account-field-container" onClick={() => { this.emailClicked()}}>
                                                                <b>Email</b>
                                                                <div className="account-field-value">{this.state.email}</div>
                                                                <img src={penIcon} alt="small-pen" className="small-pen"/>
                                                            </div>
                                                        )}

                                                        {this.state.emailClicked && (
                                                            <div className="account-field-container">
                                                                <b>Email</b>
                                                                <input type="text" value={this.state.email} onChange={(e) => {this.setState({email: e.target.value})}} id="email-input" className="account-info-input"/>
                                                                <Button variant="primary" size="lg" onClick={() => {this.emailSaved()}}>Save</Button>
                                                            </div>
                                                        )}

                                                        <div className="social-media-saved" ref={this.emailSavedError}>
                                                            The email you tried to save isn't formatted correctly!
                                                        </div>
                                                        

                                                        {!this.state.fullnameClicked && (
                                                            <div className="account-field-container" onClick={() => { this.fullnameClicked()}}>
                                                                <b>Full Name</b>
                                                                <div className="account-field-value">{this.state.fullName}</div>
                                                                <img src={penIcon} alt="small-pen" className="small-pen"/>
                                                            </div>
                                                        )}
                                                        {this.state.fullnameClicked && (
                                                            <div className="account-field-container">
                                                                <b>Full Name</b>
                                                                <input type="text" value={this.state.fullName} onChange={(e) => {this.setState({fullName: e.target.value})}} id="fullname-input" className="account-info-input"/>
                                                                <Button variant="primary" size="lg" onClick={() => {this.fullnameSaved()}}>Save</Button>
                                                            </div>
                                                        )}

                                                        {!this.state.descriptionClicked && (
                                                            <div className="account-field-container" onClick={() => { this.descriptionClicked()}}>
                                                                <b>Description</b>
                                                                <div className="account-field-value">{this.state.description}</div>
                                                                <img src={penIcon} alt="small-pen" className="small-pen"/>
                                                            </div>
                                                        )}
                                                        {this.state.descriptionClicked && (
                                                            <div className="account-field-container">
                                                                <b>Description</b>
                                                                <textarea rows="10" onChange={(e) => {this.setState({description: e.target.value})}} value={this.state.description} id="description-input" className="account-info-input"/>
                                                                <Button variant="primary" size="lg" onClick={() => {this.descriptionSaved()}}>Save</Button>
                                                            </div>
                                                        )}

                                                        <Accordion defaultActiveKey="0">
                                                            <Card className="social-media-container">
                                                                <div className="account-field-container">
                                                                    <b>Social Media Links</b>
                                                                    <Accordion.Toggle as={Button} variant="link" eventKey="1" className="view-social-media-button">
                                                                        <img src={plusIcon} alt="small-pen" className="small-plus"/>
                                                                    </Accordion.Toggle>
                                                                </div>
                                                                <Accordion.Collapse eventKey="1">
                                                                    <>
                                                                        <div className="account-field-container">
                                                                            <b>Instragram</b>
                                                                            <input type="text" value={this.state.instagramLink} onChange={(e) => {this.setState({instagramLink: e.target.value})}} className="account-info-input"/>
                                                                        </div>
                                                                        <div className="account-field-container">
                                                                            <b>Twitter</b>
                                                                            <input type="text" value={this.state.twitterLink} onChange={(e) => {this.setState({twitterLink: e.target.value})}} className="account-info-input"/>
                                                                        </div>
                                                                        <div className="account-field-container">
                                                                            <b>Other Link</b>
                                                                            <input type="text" value={this.state.otherLink} onChange={(e) => {this.setState({otherLink: e.target.value})}} className="account-info-input"/>
                                                                        </div>
                                                                        <div className="social-media-saved" ref={this.socialMediaError}>
                                                                            One of your links isn't formatted correctly!
                                                                        </div>
                                                                        <div className="social-media-saved" id="social-media-saved">
                                                                            Social Media info successfully saved!
                                                                        </div>
                                                                        <div className="save-social-media-container">
                                                                            <Button variant="primary" size="lg" onClick={() => {this.socialMediaSaved()}}>Save</Button>
                                                                        </div>
                                                                    </>
                                                                </Accordion.Collapse>
                                                            </Card>
                                                        </Accordion>
                                                    </div>
                                                </>
                                            )}
                                            {this.state.stories && (
                                                <div className="businerss_news">
                                                    {this.state.hideTabSelection && this.state.isMobile && (
                                                        <div className="sub-account-nav-title">
                                                            <img src={previousIcon} alt="profile-image" className="prev-icon" onClick={() => this.setState({hideTabSelection: false})}/>
                                                            <h3>Your Stories</h3>
                                                            <div></div>
                                                        </div>
                                                    )}
                                                    <div className="user-navbar-lower">
                                                        <div className="user-navbar-lower-tab blue-underline" ref={this.publishedButtonRef} onClick={() => {this.setButtonUnderline(this.publishedButtonRef, this.savedForLaterButtonRef); this.setState({showPublished: true, showSavedForLater: false})}}>
                                                            Published
                                                        </div>
                                                        <div className="user-navbar-lower-tab" ref={this.savedForLaterButtonRef} onClick={() => {this.setButtonUnderline(this.savedForLaterButtonRef, this.publishedButtonRef); this.setState({showSavedForLater: true, showPublished: false})}}>
                                                            Work in Progress
                                                        </div>
                                                        {/* <div className="user-navbar-lower-tab" ref={this.feedButtonRef} onClick={() => {this.setButtonUnderline(this.ideasButtonRef, this.infoButtonRef, this.storiesButtonRef); this.setState({showInfo: false, showFeed: true, showStories: false})}}>
                                                            Ideas
                                                        </div> */}
                                                    </div>
                                                    {this.state.showPublished && (
                                                        <div className="row">
                                                            {this.state.userStories != null && this.state.userStories.map((story, index) => {
                                                                return (
                                                                <div className="col-md-6 story-col">
                                                                    <div className="story-card-container">
                                                                        <div class="story-card" onClick={() => {this.storyClicked(story.id)}}>
                                                                            <div>
                                                                                {story.allStoryInformation.imgURL != null && (
                                                                                    <img src={story.allStoryInformation.imgURL} alt={"Story image for " + story.allStoryInformation.title} className="sample-story-image" />
                                                                                )}
                                                                                {story.allStoryInformation.imgURL == null && (
                                                                                    <>
                                                                                        {story.allStoryInformation.tags[0] === "Horror" && (
                                                                                            <img src={horrorImage} alt={"Horror category default story image."} className="sample-story-image" />
                                                                                        )}
                                                                                        {story.allStoryInformation.tags[0] === "Thriller" && (
                                                                                            <img src={thrillerImage} alt={"Thriller category default story image."} className="sample-story-image" />
                                                                                        )}
                                                                                        {story.allStoryInformation.tags[0] === "Fantasy" && (
                                                                                            <img src={fantasyImage} alt={"Fantasy category default story image."} className="sample-story-image" />
                                                                                        )}
                                                                                        {story.allStoryInformation.tags[0] === "Supernatural" && (
                                                                                            <img src={supernaturalImage} alt={"Supernatural category default story image."} className="sample-story-image" />
                                                                                        )}
                                                                                        {story.allStoryInformation.tags[0] === "Science Fiction" && (
                                                                                            <img src={scifiImage} alt={"SciFi category default story image."} className="sample-story-image" />
                                                                                        )}
                                                                                        {story.allStoryInformation.tags[0] === "Historical Fiction" && (
                                                                                            <img src={histFicImage} alt={"Historical Fiction category default story image."} className="sample-story-image" />
                                                                                        )}
                                                                                    </>
                                                                                )}

                                                                                <div className="story-preview-content">
                                                                                    <div className="flex-row">
                                                                                        <div className="story-card-title">{story.allStoryInformation.title}</div>
                                                                                    </div>

                                                                                    {/* <div className="tags-row">
                                                                                        {story.allStoryInformation.tags.map((tag, index2) => {
                                                                                            return (
                                                                                                <div className="category-tag">
                                                                                                    <img src={tagIcon} alt="Tag Icon" className="tag-icon" />
                                                                                                    <div className="story-tag">{tag}</div>
                                                                                                </div>
                                                                                            )
                                                                                        })}
                                                                                    </div> */}

                                                                                    <div className="story-stats">
                                                                                        <div className="flex-row">
                                                                                            <img src={eyeIcon} alt="View count" className="eye-icon-small" />
                                                                                            <div className="story-views-small-blue">{story.allStoryInformation.viewcount}</div>
                                                                                        </div>
                                                                                        <div className="flex-row">
                                                                                            <img src={thumbIcon} alt="Thumbs up count" className="eye-icon-small" />
                                                                                            <div className="story-views-small">{story.allStoryInformation.thumbsUp.length}</div>
                                                                                        </div>
                                                                                        <div className="flex-row">
                                                                                            <img src={commentsIcon} alt="View count" className="eye-icon-small" />
                                                                                            <div className="story-views-small">{story.allStoryInformation.commentsCount}</div>
                                                                                        </div>
                                                                                        <div className="flex-row">
                                                                                            <img src={shareSmallIcon} alt="View count" className="eye-icon-small" />
                                                                                            <div className="story-views-small">{story.allStoryInformation.shares.facebook + story.allStoryInformation.shares.twitter + story.allStoryInformation.shares.tumblr + story.allStoryInformation.shares.email + story.allStoryInformation.shares.link}</div>
                                                                                        </div>
                                                                                        {story.allStoryInformation.tags.map((tag, index2) => {
                                                                                            return (
                                                                                                <div className="story-tag">{tag.toUpperCase()}</div>
                                                                                            )
                                                                                        })}
                                                                                    </div>

                                                                                </div>

                                                                            </div>
                                                                    

                                                                        <div className="story-preview-content">
                                                                            <div className="story-card-body">{story.allStoryInformation.description}</div>
                                                                        </div>
                    
                                                                        <div className="user-stories-button-row">
                                                                            <Button variant="secondary" size="lg" className="pad-right-10" onClick={(e) => this.deleteClicked(e, story)}>Delete</Button>
                                                                            <Button variant="primary" size="lg" className="pad-right-10" onClick={(e) => this.editClicked(e, story)}>Edit Story</Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            )
                                                        })}
                                                            <div className="col-md-6 story-col">
                                                                <Link className="plus-button" to="/write">
                                                                    <div>Add Story</div>
                                                                    <img src={plusIcon} alt="plus icon" className="add-story-plus"/>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {this.state.showSavedForLater && (
                                                        <div className="row">
                                                            {this.state.userSavedStories != null && this.state.userSavedStories.map((story, index) => {
                                                                return (
                                                                    <div className="col-md-6 story-col">
                                                                        <div class="story-saved">
                                                                            <div className="flex-row">
                                                                                <div className="story-card-title">{story.title}</div>
                                                                            </div>
                                                                            <div className="story-card-body">{story.description}</div>
                                                                            <div className="space-15"/>
                                                                            <div className="center-content">
                                                                                <Button variant="primary" size="lg" onClick={() => {this.savedStoryClicked(story)}}>Continue Writing</Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                            <div className="col-md-6 story-col">
                                                                <Link className="plus-button" to="/write">
                                                                    <div>Add Story</div>
                                                                    <img src={plusIcon} alt="plus icon" className="add-story-plus"/>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            {this.state.favorites && (
                                                <div className="businerss_news">
                                                    {this.state.hideTabSelection && this.state.isMobile && (
                                                        <div className="sub-account-nav-title">
                                                            <img src={previousIcon} alt="profile-image" className="prev-icon" onClick={() => this.setState({hideTabSelection: false})}/>
                                                            <h3>Favorites</h3>
                                                            <div></div>
                                                        </div>
                                                    )}
                                                    <div className="row">
                                                        {this.props.currentUser.favorites != null && this.props.currentUser.favorites.length != 0 && this.props.currentUser.favorites.map((storyID, index) => {

                                                            let story = this.props.allStories.find(o => o.id === storyID);

                                                            if (story.hidden == null) {
                                                                                                                    
                                                                let userInfo = this.props.allUsers.find(o => o.uid === story.creatorID);

                                                                return (
                                                                    <StoryPreview story={story} userInfo={userInfo} colWidth={4}/>
                                                                )
                                                            }
                                                        })}
                                                    </div>
                                                    {(this.props.currentUser.favorites == null || this.props.currentUser.favorites.length == 0) && (
                                                        <h3 className="padding-top-40">No favorites to display yet! Keep searching for stories you love and click the star icon.</h3>
                                                    )}
                                                </div>
                                            )}
                                            {this.state.addPrompt && (
                                                <div className="businerss_news">
                                                    {this.state.hideTabSelection && this.state.isMobile && (
                                                        <div className="sub-account-nav-title">
                                                            <img src={previousIcon} alt="profile-image" className="prev-icon" onClick={() => this.setState({hideTabSelection: false})}/>
                                                            <h3>Add Prompt</h3>
                                                            <div></div>
                                                        </div>
                                                    )}
                                                    {!this.state.promptAdded && (
                                                        <>
                                                            <h4>Enter the name of the prompt below:</h4>
                                                            <input type="text" onChange={(e) => {this.setState({promptToAdd: e.target.value})}} id="email-input" className="account-info-input"/>
                                                            <Button variant="primary" size="lg" onClick={() => {this.addPrompt()}}>Save</Button>
                                                        </>
                                                    )}
                                                    {this.state.promptAdded && (
                                                        <>
                                                            <h4>Prompt added successfully! Go to prompts and refresh the page.</h4>
                                                            <Button variant="primary" size="lg" onClick={() => {this.setState({promptAdded: false})}}>Add Another</Button>
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                            {this.state.contactUs && (
                                                <div className="businerss_news">
                                                    <h1>Contact Us Tickets</h1>
                                                    {this.state.contactUsMessages?.map((message) => {
                                                        if (!message.read) {
                                                            const date = new Date(message.message.date);
                                                            const dateString = toFullMonth(date.getMonth()) + " " + date.getDate() + ", " + date.getFullYear();

                                                            return (
                                                                <div>
                                                                    <p></p>
                                                                    <h3>{message.message.subject}</h3>
                                                                    <b>{dateString}</b>
                                                                    <div>{message.message.email} - {message.message.name}</div>
                                                                    <div>{message.message.message}</div>
                                                                    <Button onClick={() => this.clickedReadContactUs(message.message.subject)}>Mark as Read</Button>
                                                                    <p></p>
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                </div>
                                            )}
                                            {this.state.publications && (
                                                <div className="businerss_news">
                                                    <h1>Stories Considered for Publication</h1>
                                                    {this.props.allStories.map((story) => {
                                                        if (story.allStoryInformation.publicationSlider) {
                                                            return (
                                                                <><Link to={`/story/${story.id}`}>{story.allStoryInformation.title}</Link><br/></>
                                                            )
                                                        }
                                                    })}
                                                </div>
                                            )}
                                            {this.state.notifications && (
                                                <>
                                                    {this.state.hideTabSelection && this.state.isMobile && (
                                                        <div className="sub-account-nav-title">
                                                            <img src={previousIcon} alt="profile-image" className="prev-icon" onClick={() => this.setState({hideTabSelection: false})}/>
                                                            <h3>Notifications</h3>
                                                            <div></div>
                                                        </div>
                                                    )}
                                                    <div className="notifications-container">
                                                        {this.props.currentUser.notifications?.list.length != 0 && this.props.currentUser.notifications?.list.map((notification, index) => {
                                                            return (
                                                                <Link className="individual-notification" to={`/story/${notification.storyId}`} onClick={() => { this.notificationClicked(notification, index) }}>
                                                                    {!(notification.read) && (
                                                                        <div className="notification-dot-container-table"><div className="notification-dot" /></div>
                                                                    )}
                                                                    <>{notification.message}</>
                                                                </Link>
                                                            )
                                                        })}
                                                        {this.props.currentUser.notifications?.list.length == 0 && (
                                                            <h3> No notifications to display! </h3>
                                                        )}
                                                    </div>
                                                    {this.props.currentUser.notifications?.list.length != 0 && (
                                                        <a className="clear-all" onClick={() => {this.clearAllNotifications()}}>Mark All Read</a>
                                                    )}
                                                </>
                                            )}
                                            {this.state.messages && (
                                                <>
                                                    {this.state.hideTabSelection && this.state.isMobile && (
                                                        <div className="sub-account-nav-title">
                                                            <img src={previousIcon} alt="profile-image" className="prev-icon" onClick={() => this.setState({hideTabSelection: false})}/>
                                                            <h3>Messages</h3>
                                                            <div></div>
                                                        </div>
                                                    )}
                                                    <Messages history={this.props.history} regrabUserInfo={() => { regrabUserInfoFB(this.props.dispatch, this.props.currentUser.uid); }} currentUser={this.props.currentUser} allUsers={this.props.allUsers}/>
                                                </>
                                            )}
                                            {this.state.submissions && (
                                                <div>
                                                    {this.state.hideTabSelection && this.state.isMobile && (
                                                        <div className="sub-account-nav-title">
                                                            <img src={previousIcon} alt="profile-image" className="prev-icon" onClick={() => this.setState({hideTabSelection: false})}/>
                                                            <h3>Submissions</h3>
                                                            <div></div>
                                                        </div>
                                                    )}
                                                    {this.props.currentUser.submissions && (
                                                        <div>
                                                            {Object.keys(this.props.currentUser.submissions).map((s) => {
                                                                let submissionTitle = s;
                                                                if (submissionTitle === 'anthology-v2') submissionTitle = 'SF Anthology - Volume Two';
                                                                else if (submissionTitle === 'campfire-v1') submissionTitle = 'SF Campfire Stories - Volume One';

                                                                console.log(s);
                                                                let status = this.props.currentUser.submissions[s].status;
                                                                if (status === 'review') status = 'Under Review';
                                                                else if (status === 'accepted') status = 'Accepted';

                                                                return (
                                                                    <div className="prompt-container" onClick={(() => {
                                                                        this.props.history.push(`/submit/${s}`);
                                                                    })}>
                                                                        <div className="prompt-title">
                                                                            {submissionTitle}
                                                                        </div>
                                                                        <div className="prompt-description">
                                                                            Story: {this.props.currentUser.submissions[s].title}
                                                                        </div>
                                                                        <div className="prompt-description">
                                                                            Status: {status}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    )}
                                                </div>                                                
                                            )}
                                            {this.state.adminSubmissions && (
                                                <div>
                                                    {this.state.hideTabSelection && this.state.isMobile && (
                                                        <div className="sub-account-nav-title">
                                                            <img src={previousIcon} alt="profile-image" className="prev-icon" onClick={() => this.setState({hideTabSelection: false})}/>
                                                            <h3>Admin Submissions</h3>
                                                            <div></div>
                                                        </div>
                                                    )}

                                                    {this.props.submissionDetails && (
                                                                <div>
                                                                    <h3>{this.state.selectedSubmissionPrompt} Submissions ({this.props.submissionDetails.length})</h3>
                                                                    {this.props.submissionDetails.sort((a,b) => b.timestamp - a.timestamp).map((submission) => {
                                                                        return (
                                                                            <div className="submission-container">
                                                                                <div className="prompt-title">
                                                                                    {submission.title}
                                                                                </div>
                                                                                <div className="prompt-description">
                                                                                    Name: {submission.name}
                                                                                </div>
                                                                                <div className="prompt-description">
                                                                                    Pen Name: {submission.penName}
                                                                                </div>
                                                                                <div className="prompt-description">
                                                                                    Email: {submission.email}
                                                                                </div>
                                                                                <div className="prompt-description">
                                                                                    Social Media: {submission.socMedia}
                                                                                </div>
                                                                                <div className="prompt-description">
                                                                                    Date: {submission.timestamp.toDate().toLocaleDateString("en-US")}
                                                                                </div>
                                                                                <div className="prompt-description">
                                                                                    Bio: {submission.bio}
                                                                                </div>
                                                                                <div className="prompt-description">
                                                                                    <a href={submission.fileURL}> Download Story </a>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                    )} 

                                                    {!this.props.submissionDetails && this.props.allSubmissions.map((s) => {
                                                            return (
                                                                <div className="prompt-container" onClick={(() => { requestSubmissionDetails(this.props.dispatch, s.title); this.setState({ selectedSubmissionPrompt: s.title}); })}>
                                                                    <div className="prompt-title">
                                                                        {s.title}
                                                                    </div>
                                                                </div>
                                                            )
                                                    })}
                                                        
                                                        
                                                </div>
                                            )}
                                        <div className="experiencing-issues-question">
                                            Experiencing issues with the site or have questions? Contact us <Link to="/contact">here</Link>.
                                        </div>
                                    </>
                                    
                                )}
                            </div>
                        </div>
                    </div>
                    <OpenImageModal dispatch={this.props.dispatch} regrabUserInfo={this.props.regrabUserInfo} getUserStories={this.getUserStories} currentUser={this.props.currentUser} showModal={this.state.openImageModal} closeModal={() => {this.closeImageModal()}}/>
                    <LoggedOutModal showModal={this.state.showLoggedOutModal} closeModal={() => {this.closeLoggedOutModal()}}/>
                    {this.state.storyToDelete != null && (
                        <DeleteStoryModal storyToDelete={this.state.storyToDelete} showModal={this.state.showDeleteModal} closeModalStoryDeleted={() => {this.closeModalStoryDeleted()}} closeModal={() => {this.closeDeleteModal()}} currentUser={this.props.currentUser}/>
                    )}
                    {this.state.storyToEdit != null && (
                        <EditStoryModal allPrompts={this.props.allPrompts} savedInfo={this.state.storyToEdit} showModal={this.state.showEditModal} closeModal={() => {this.closeEditModal()}} closeEditModalStoryEdited={this.closeEditModalStoryEdited} currentUser={this.props.currentUser}/>
                    )}
                    <AddMoreInfoModal showModal={this.state.showAddMoreModal} closeModal={() => {this.closeAddMoreModal()}}/>
                </div>
                <div className="space-100"/>
                {/* <BannerSection className="parimay_bg padding5050"/> */}
            </Fragment>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.primary.currentUser,
        allPrompts: state.primary.allPrompts,
        allUsers: state.primary.allUsers,
        allStories: state.primary.allStories,
        allSubmissions: state.primary.allSubmissions,
        submissionDetails: state.primary.submissionDetails
    }
}

export default connect (mapStateToProps) (AccountPage)